import React from 'react'
import styled from '@emotion/styled'
import { MetaData } from '@jelly/components'

import AdvertiseLayout from '../_components/AdvertiseLayout'
import AdvertiseForm from '../_components/AdvertiseForm'
import SalesPitch from './_components/SalesPitch'
import BodyRight from './_components/BodyRight'

const MainContainer = styled.main`
	align-items: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0px 10px 70px;
	position: relative;

	${({ theme }) => theme.MQ.L} {
		align-items: flex-start;
		flex-direction: row;
		justify-content: center;
	}
`
const LeftMain = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	${({ theme }) => theme.MQ.S} {
		min-width: 545px;
	}

	${({ theme }) => theme.MQ.L} {
		justify-content: space-around;
		max-width: 475px;
		min-width: 475px;
		padding-right: 30px;
	}
`

const RightMain = styled.div`
	max-width: 365px;
	position: relative;
	width: 100%;

	${({ theme }) => theme.MQ.S} {
		max-width: 675px;
	}

	${({ theme }) => theme.MQ.L} {
		align-self: stretch;
		max-width: 410px;
		padding-left: 30px;
		height: 725px;
	}
`

const AdvertiseMetaData = {
	title: 'Acquire more customers with business.com advertising',
	canonical: `${process.env.GATSBY_SITE_HOST}/connect/v1/`,
	description:
		' business.com, the leader in online B2B lead generation, delivers high quality marketing programs with the ROI needed to help your business grow.',
}

const v1 = () => {
	return (
		<AdvertiseLayout>
			<MetaData meta={AdvertiseMetaData} />
			<MainContainer>
				<LeftMain>
					<SalesPitch />
					<AdvertiseForm src="https://connect.business.com/l/900901/2021-10-13/4k6f2" version="v1" />
				</LeftMain>
				<RightMain>
					<BodyRight />
				</RightMain>
			</MainContainer>
		</AdvertiseLayout>
	)
}

export default v1
